<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                  Ajouter un tag
                </h3>
                <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                  Modifier un tag
                </h3>
              </div>
              <!--begin::Form-->
              <form class="form" @submit.prevent="save">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>Nom:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.name"
                        :class="{ 'is-invalid': errors.name }"
                      />
                      <span class="form-text text-danger" v-if="errors.name">{{
                        errors.name[0]
                      }}</span>
                    </div>
                    <div class="col-lg-6">
                      <label>Type de tag</label>
                      <multiselect
                        v-model="form.typeTag"
                        :options="types"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        placeholder="Choisir un type"
                        label="nom"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        @input="setTypeTagId()"
                      ></multiselect>
                      <span
                        class="form-text text-danger"
                        v-if="errors.typetag_id"
                        >{{ errors.typetag_id[0] }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-lg-12 text-right">
                      <button
                        type="submit"
                        ref="tag_submit"
                        class="btn btn-primary mr-2"
                      >
                        Enregistrer
                      </button>
                      <button type="reset" class="btn btn-secondary">
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Card-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  name: "Form",
  components: { Multiselect },
  data() {
    return {
      initialize: "tag/create",
      store: "tag",
      method: "post",
      form: {},
      types: [],
      success: false,
      has_error: false,
      error: "",
      errors: {}
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "tag/" + this.$route.params.id + "/edit";
      this.store = "tag/" + this.$route.params.id;
      this.method = "put";
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.form = response.data.form;
        vm.types = response.data.typeTags;
      });
    },
    setTypeTagId() {
      this.form.typetag_id = this.form.typeTag.id;
    },
    save() {
      let vm = this;
      const submitButton = this.$refs["tag_submit"];
      submitButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
      ApiService[this.method](this.store, vm.form)
        .then(function(response) {
          if (response.data.success) {
            Swal.fire({
              title: "Reussie",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
            vm.$router.push(vm.$route.meta.back);
          }
        })
        .catch(function(error) {
          vm.errors = error.response.data.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
    }
  }
};
</script>

<style scoped></style>
